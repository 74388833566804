<template>
  <div>
    <van-nav-bar
      title="修改登录密码"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="旧密码"
        placeholder="请输入旧密码"
        :rules="[{ required: true, message: '请输入旧密码' }]"
      />
      <van-field
        v-model="newPassword"
        type="password"
        name="newPassword"
        label="新密码"
        placeholder="请输入新密码"
        :rules="[
          { required: true, message: '请输入新密码' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/, message: '密码最少6位，由数字和字母组成'}
        ]"
      />
      <van-field
        v-model="rePassword"
        type="password"
        name="rePassword"
        label="确认密码"
        placeholder="请再次输入新密码"
        :rules="[
          { required: true, message: '请再次输入新密码' },
          { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/, message: '密码最少6位，由数字和字母组成'}
        ]"
      />
      <div class="margin-20">
        <van-button round block type="warning" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { updatePassword } from '@/utils/api.js'
export default {
  name: 'setPassword',
  data () {
    return {
      password: '',
      newPassword: '',
      rePassword: ''
    }
  },
  methods: {
    onSubmit () {
      if (this.newPassword !== this.rePassword) {
        this.$toast('两次密码输入不一致')
        return
      }
      const param = { password: this.password, newPassword: this.newPassword }
      updatePassword(param).then(res => {
        if (res.code === 200) {
          this.$toast.success({
            message: res.message,
            duration: 1500,
            onClose: () => {
              this.onClickLeft()
            }
          })
        } else {
          this.$toast.fail(res.message)
        }
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
